import * as Sentry from '@sentry/remix'

/**
 * Error handling
 * @ref https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
interface ErrorWithMessage {
  message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}

/**
 * Makes an external API request
 */
interface MakeRequest {
  url: string
  options?: {
    method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
    headers?: any
    body?: any
  }
  allowTimeout?: boolean
  timeoutSeconds?: number
}

export async function makeRequest({
  url,
  options,
  allowTimeout = true,
  timeoutSeconds = 5,
}: MakeRequest) {
  const headersToUse = {
    'content-type': 'application/json',
    ...options?.headers,
  }

  try {
    const controller = new AbortController()
    const timeoutSignal = AbortSignal.timeout(timeoutSeconds * 1000)

    const response = await fetch(url, {
      method: options?.method ?? 'GET',
      headers: headersToUse,
      body: options?.body,
      signal: allowTimeout
        ? AbortSignal.any([controller.signal, timeoutSignal])
        : null,
    })

    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${response.status} status from ${url}`)
    }

    if (
      headersToUse['content-type'] == 'application/json' ||
      headersToUse['content-type'] == 'application/vnd.api+json'
    ) {
      return response.json()
    } else {
      return response
    }
  } catch (error) {
    // @NOTE: NOT WORKING
    Sentry.captureException(error)

    const message = getErrorMessage(error)

    throw new Error(`${message ? message : 'Unknown error'}`)
  }
}

// Deferred promise
export const deferred = () => {
  let resolve: any
  let reject: any
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  return { resolve, reject, promise }
}

// Return front-end response
export const returnUserResponse = (success: boolean, message: string) => {
  return {
    success: success,
    message: message,
  }
}

export const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const toRad = (x: number) => (x * Math.PI) / 180
  const R = 6371 // Radius of the Earth in km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon1 - lon2)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c
}
