import slugify from 'slugify'
import { toFixedNumber } from '~/utils'
import type {
  GetPricesHireData,
  ExtrasItem,
  ItemSiteStock,
  ItemAvailability,
  PickupPoint,
  LunarProductPreBasket,
  LineItemProps,
  ExtrasProduct,
  HireProductData,
  ItemTotals,
} from '~/types'

// Returns the pickup points array
export const mapPickupPoints = (
  pickupPoints?: GetPricesHireData['pickupPoints']
) => {
  return pickupPoints && pickupPoints.length !== 0 ? pickupPoints : []
}

// Returns the pickup point address and branch code
export const getPickupLocation = (pickupPoint: PickupPoint) => {
  if (!pickupPoint) {
    return null
  }

  const siteLocation = pickupPoint?.siteLocation
  const address = [
    siteLocation?.address1,
    siteLocation?.city,
    siteLocation?.postcode,
  ].join(', ')

  return {
    branchId: pickupPoint?.id,
    address: address,
  }
}

// Determines the max quantity value to set
const setExtrasMaxQuantity = (
  type: 'consumable' | 'bulk',
  stock?: ItemSiteStock
): number => {
  return stock?.maxQuantity ? stock.maxQuantity : type == 'bulk' ? 10 : 100
}

// Determines if the extra is available or not
const setExtrasAvailability = (inStock?: ItemAvailability): boolean => {
  if (!inStock) {
    return true
  }

  if (!inStock.isAvailable) {
    return false
  } else {
    return true
  }
}

// Sets the correct product price using get prices data
export const getProductPrices = (itemTotals: ItemTotals) => {
  // Hire price excl. VAT
  const hirePrice =
    itemTotals?.hireTotal?.amount && itemTotals?.hireVat?.amount
      ? {
          currency: itemTotals?.hireTotal?.currency ?? 'GBP',
          amount: toFixedNumber(
            itemTotals.hireTotal.amount + itemTotals.hireVat.amount,
            2
          ),
        }
      : null

  // Buy price excl. VAT
  const buyPrice =
    itemTotals?.resaleTotal?.amount && itemTotals?.resaleVat?.amount
      ? {
          currency: itemTotals?.resaleTotal?.currency ?? 'GBP',
          amount: toFixedNumber(
            itemTotals.resaleTotal.amount + itemTotals.resaleVat.amount,
            2
          ),
        }
      : null

  // Transport price incl. VAT
  const transportPrice =
    itemTotals?.transportTotal?.amount && itemTotals?.transportVat?.amount
      ? {
          currency: itemTotals?.transportTotal?.currency ?? 'GBP',
          amount: toFixedNumber(
            itemTotals.transportTotal.amount + itemTotals.transportVat.amount,
            2
          ),
        }
      : null

  return {
    hirePrice,
    buyPrice,
    transportPrice,
  }
}

// Map the cross sells data so we have a uniform set of data
export const mapExtras = (
  type: 'consumable' | 'bulk',
  products?: ExtrasItem[]
): ExtrasProduct[] => {
  return products && products.length !== 0
    ? products
        .map(e => {
          const { hirePrice, buyPrice, transportPrice } = getProductPrices(
            e.itemTotals
          )

          const noPrice = {
            amount: 0,
            currency: 'GBP',
          }

          const priceToUse =
            type == 'bulk' && hirePrice?.amount
              ? hirePrice
              : type == 'consumable' && buyPrice?.amount
                ? buyPrice
                : noPrice

          return {
            title: e.product.name,
            image: e.product.image,
            stockCode: e.product.stockCode?.toString(),
            price: priceToUse,
            productType: type,
            maxQuantity: setExtrasMaxQuantity(type, e?.siteWithStock),
            damageWaiver: e?.damageWaiver ?? 0,
            deliveryDate: e?.deliveryDate ?? null,
            deliveryPrice: transportPrice ?? noPrice,
            isAvailable: setExtrasAvailability(e?.availability),
            isMandatory: e?.isMandatory ? true : false,
          }
        })
        .filter(e => e?.price?.amount)
    : []
}

// Maps our data keys to the ones required for Lunar basket
const basketKeyMap = [
  {
    name: 'productType',
    basketKey: 'product_type',
  },
  {
    name: 'deliveryPostcode',
    basketKey: 'delivery_postcode',
  },
  {
    name: 'deliveryAddress',
    basketKey: 'delivery_address',
  },
  {
    name: 'deliveryPrice',
    basketKey: 'delivery_price',
  },
  {
    name: 'deliveryDate',
    basketKey: 'delivery_date',
  },
  {
    name: 'fulfilment',
    basketKey: 'selected_fulfilment',
  },
  {
    name: 'pickupBranchId',
    basketKey: 'pickup_branch_id',
  },
  {
    name: 'pickupBranchAddress',
    basketKey: 'pickup_branch_address',
  },
  {
    name: 'hireDeposit',
    basketKey: 'hire_deposit',
  },
  {
    name: 'hireDamageWaiver',
    basketKey: 'hire_damage_waiver',
  },
  {
    name: 'hireStartDate',
    basketKey: 'hire_start_date',
  },
  {
    name: 'hireEndDate',
    basketKey: 'hire_end_date',
  },
  {
    name: 'hireGroupId',
    basketKey: 'hire_group_id',
  },
  {
    name: 'hireParentGroupId',
    basketKey: 'hire_parent_group_id',
  },
  {
    name: 'isMandatory',
    basketKey: 'is_mandatory',
  },
]

export const mapBasketItem = (
  product: LunarProductPreBasket
): LineItemProps => {
  const meta = {
    price: product?.price,
  }

  for (const [key, value] of Object.entries(product)) {
    const mappedItem = basketKeyMap.find(x => x.name == key)

    if (mappedItem) {
      Object.assign(meta, { [mappedItem.basketKey]: value })
    }
  }

  return {
    quantity: product.quantity,
    stock_code: product.stockCode,
    meta: meta,
  }
}

// Gets the links for PDP pages of products
export const getProductPageLink = ({
  productType,
  stockCode,
  slug,
  variant,
}: {
  productType: string
  stockCode: string
  slug: string
  variant?: string
}): string => {
  let url = `/${productType == 'bulk' ? 'hire' : 'buy'}/p/${stockCode}/${slug}`

  if (variant && variant !== 'default') {
    url = `${url}?variant=${slugify(variant)}`
  }

  return encodeURI(url.toLowerCase())
}

// Determines if the product has additional extras or not
export const doesProductHaveExtras = (data: HireProductData) => {
  return (data?.addOns && data?.addOns?.length > 0) ||
    (data?.crossSells && data?.crossSells?.length > 0)
    ? true
    : false
}
